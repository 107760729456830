import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import './styles/Main.css';
import MainPage from './Components/Pages/MainPage';
import Workspace from './Components/Pages/Workspace/Workspace';
import About from './Components/Pages/About';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<MainPage />}></Route>
        <Route path='/workspace' element={<Workspace />}></Route>
        <Route path='/about' element={<About />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
