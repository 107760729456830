import { Box, Typography, Button, styled, IconButton, FormControl, FormControlLabel, Switch } from '@mui/material';
import React, { useState } from 'react';
import { AddCircleOutline, CloudUploadOutlined, ClearAllOutlined, Delete as DeleteIcon, Save as DownloadIcon, FileOpen as UploadIcon } from '@mui/icons-material';
import MySelect from './ToolPanelComponents/MySelect';
import MyDataGrid from './ToolPanelComponents/MyDataGrid';
import MyModal from './ToolPanelComponents/MyModal';
import {randomId} from '@mui/x-data-grid-generator'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ToolPanel = ({columns, setColumns, rows, setRows, asRenderData, setAsRenderData, renderDataVariant, isField, setIsField, saveImage}) => {
  const [openModal, setOpenModal] = useState(false); // Состояние модального окна
  const [tmpColumnName, setTmpColumnName] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);

  //Изменить состояние отображения
  const asRenderDataChange = (e) => {
    setAsRenderData(e.target.value);
  };

  const removeElementsByIndices = (arr, indices) => {
    let indicesSet = new Set(indices);
    let result = arr.filter((_, index) => !indicesSet.has(index + 1));

    return result;
}

// Событие нажатия на кнопкуочистки таблицы
  const deleteAllData = () => {
    setRows(removeElementsByIndices(rows, selectedRow));
    // setColumns([]);
    // setRows([]);
  }

  // Событие нажатия на кнопку добавить колонку
  const addColumn = () => {
    setOpenModal(true);
  };

  // Добавить строку
  const addRow = () => {
    const newRow = { id: randomId()};
  
  // Для каждого столбца устанавливаем начальное значение как пустую строку
  columns.forEach(column => {
    newRow[column.field] = 0;
  });

  // console.log(newRow);

  setRows(prevRows => [...prevRows, newRow]);
  };

  // Закрыть модальное окно
  const closeModal = () => setOpenModal(false);

  // Сохранить данные из модального окна
  const saveModalData = () => {
    if (tmpColumnName == '') {
      return;
    }
    setColumns(prevColumns => [
      ...prevColumns,
      { field: tmpColumnName, type: 'number', headerName: tmpColumnName, width: 150, editable: true }
    ]);
    setOpenModal(false);
  };

  //Событие нажатия на кнопку загрузки файла
  const downloadData = () => {
    downloadCSV(rows, columns);
  };

  //Скачиваем файл
  const downloadCSV = (rows, columns) => {
    const convertToCSV = (rows, columns) => {
      const columnHeaders = columns.map(column => column.headerName).join(',');
      console.log(columnHeaders);
      const csvRows = rows.map(row => {
        return columns.map(column => row[column.field]).join(',');
      });
      return [columnHeaders, ...csvRows].join('\n');
    };

    const csvContent = convertToCSV(rows, columns);

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'data.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //Импортируем файл
  const importData = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      parseCSV(text);
    };
    reader.readAsText(file);
  };

  // Парсинг в CSV
  const parseCSV = (text) => {
    const lines = text.split('\n');
    const headers = lines[0].split(',').map(header => header.trim());
    const dataRows = lines.slice(1).map(line => {
      const values = line.split(',');
      let row = {};
      headers.forEach((header, index) => {
        row[header] = values[index].trim();
      });
      return row;
    });

    setColumns(headers.map(header => ({ field: header, headerName: header, type: "number", width: 100, editable: true })));
    setRows(dataRows.map((row, index) => ({ id: index + 1, ...row })));
  };

  const changeFieldValue = (e) => {
    setIsField(e.target.checked)
  }

  const generateLabelForChecked = () => {
    if (asRenderData == 'Круговая диаграмма') {
      return 'Разделить';
    } else if (asRenderData == 'График') {
      return 'Заливка';
    }

    return '';
  }

  return (
    <Box sx={{ width: '100%', padding: '15px', overflow: 'auto' }}>
      <MyModal openModal={openModal} closeModal={closeModal} saveModalData={saveModalData} setName={setTmpColumnName} />

      <Typography sx={{ textAlign: 'center', fontSize: '20px', fontWeight: '600', margin: '10px 0', fontFamily: '"Ubuntu", sans-serif', color: '#555' }}>
        Панель инструментов
      </Typography>

      <Box>
        {/* Сохранить */}
        <IconButton onClick={downloadData} size='small'>
          <DownloadIcon sx={{color: '#2e7d32'}} />
        </IconButton>

        {/* Импорт */}
        <IconButton
          component="label"
          role={undefined}
          size='small'
        >
          <UploadIcon sx={{color: '#FFA500'}} />
          <VisuallyHiddenInput type="file" accept=".csv" onChange={importData} />
        </IconButton>

        {/* Удалить */}
        <IconButton
          size='small'
          onClick={deleteAllData}
        >
          <DeleteIcon sx={{color: '#FF4500'}} />
        </IconButton>
      </Box>

      <MyDataGrid rows={rows} columns={columns} setRows={setRows} setSelectedRow={setSelectedRow} />

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px' }}>
        <Button onClick={addColumn} startIcon={<AddCircleOutline />} color='success' sx={{ width: '100%', margin: '15px 0' }}>Стобец</Button>
        <Button onClick={addRow} startIcon={<AddCircleOutline />} color='success' sx={{ width: '100%', margin: '15px 0' }}>Строка</Button>
      </Box>

      <MySelect asRenderData={asRenderData} asRenderDataChange={asRenderDataChange} data={renderDataVariant} />
      <FormControl>
        <FormControlLabel
          control={<Switch checked={isField} onChange={changeFieldValue} color="primary" />}
          label={generateLabelForChecked()}
          labelPlacement="start"
        />
      </FormControl>

      <Button onClick={saveImage}>Сохранить картинку</Button>
    </Box>
  );
};

export default ToolPanel;
