import { Box, Button, Modal, TextField } from '@mui/material';
import React from 'react'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    background: '#fff',
    boxShadow: 24,
    padding: '20px',
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px'
  };

const MyModal = ({openModal, closeModal, saveModalData, setName}) => {
  return (
    <Modal open={ openModal } onClose={ closeModal }>
        <Box style={style}>
          <TextField id="standard-basic" onChange={(e) => (setName(e.target.value))} label="Название столбца" variant="standard" sx={{width: '100%'}} />
          <Button onClick={saveModalData} color='success' sx={{width: '100%'}}>Добавить</Button>
        </Box>
      </Modal>
  )
}

export default MyModal