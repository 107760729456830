import React, { useRef, useEffect } from 'react'
import { Button, TextField, Container, IconButton } from '@mui/material';
import style from '../../styles/MainPage.module.css';
import gsap from 'gsap';

import { NavLink, useNavigate } from 'react-router-dom';

const MainPage = () => {
    const nameAppRef = useRef(null);
    const name2AppRef = useRef(null);
    const buttonAppRef = useRef(null);
    const mainRef = useRef(null);

    const navigate = useNavigate();

    const goToWorkspace = () => {
        gsap.fromTo(
            mainRef.current,
            {
                opacity: 1,
                transform: 'translateY(0)'
            },
            {
                opacity: 0,
                transform: 'translateY(-100vh)',
                duration: 0.5
            }
        );

        setTimeout(() => {
            navigate('/workspace');
        }, 500);
    }

    useEffect(() => {
        const elements = [nameAppRef.current, name2AppRef.current, buttonAppRef.current];
    
        gsap.fromTo(
            elements,
            {
                opacity: 0,
                transform: 'translateY(100px)'
            },
            {
                opacity: 1,
                transform: 'translateY(0)',
                duration: 0.5,
                delay: index => index * 0.5
            }
        );
    }, []);

    return(

            <div ref={mainRef} className={style.App}>
                <h1 ref={nameAppRef}>Инфографика</h1>
                <h2 ref={name2AppRef}>Визуализируйте ваши данные легко и просто</h2>
                <Button variant='contained' ref={buttonAppRef} onClick={goToWorkspace}>Продолжить</Button>
            </div>
        
    )
}

export default MainPage