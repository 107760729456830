import { Box } from '@mui/material'
import React from 'react'

const FooterApp = () => {
  return (
    <Box sx={
        {
            background: '#aaa',
            color: '#fff',
            width: '100%',
            height: '70px',
            lineHeight: '70px',
            padding: '0 20px',
            borderTop: '1px solid #000'
        }
    }>
        © Инфографика, 2024
    </Box>
  )
}

export default FooterApp