import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

const MySelect = ({ asRenderData, asRenderDataChange, data }) => {
  return (
    <FormControl sx={{ width: '100%', marginTop: '20px' }}>
      <InputLabel sx={{ background: '#eee', padding: '0 10px' }}>
        Выберите как хотите визуализировать ваши данные
      </InputLabel>
      <Select value={asRenderData} onChange={asRenderDataChange}>
        <MenuItem value="">
            Не выбрано
        </MenuItem>
        {data.map((item, index) => (
          <MenuItem key={index + 1} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MySelect;
