import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, {useState, useCallback, useRef} from 'react'

const MyDataGrid = ({rows, columns, setRows, setSelectedRow}) => {
  const dataGridRef = useRef(null);

  const processRowUpdate = (newRow) => {
    const updatedRows = rows.map(row => row.id === newRow.id ? newRow : row);
    setRows(updatedRows);
    return newRow;
  };

  const handleRowSelectionChange  = (e) => {
    setSelectedRow(e);
  }

  return (
    <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          ref={dataGridRef}
          rows={rows}
          columns={columns}
          processRowUpdate={processRowUpdate}
          onRowSelectionModelChange={handleRowSelectionChange}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
  )
}

export default MyDataGrid