import { Box } from '@mui/material';
import { BarChart, LineChart, PieChart } from '@mui/x-charts';
import React, { useEffect, useState } from 'react';


const ChartPanel = ({columns, rows, asRender, setRenderDataVariant, isField, forSaveImage1Ref, forSaveImage2Ref, forSaveImage3Ref}) => {
 // Создаем пустой массив для серий
 const seriesData = [];


  useEffect(() => {
    setRenderDataVariant(['Столбчатая диаграмма', 'График', 'Круговая диаграмма']);
  }, [])

  // setRenderDataVariant(['Диаграмма 1', 'Диаграмма 2']);

 // Перебираем столбцы
 columns.forEach(column => {
  // Создаем массив данных для текущего столбца
  const columnData = rows.map(row => Number(row[column.field]) || 0);
  // Добавляем данные в массив серий, включая подпись из заголовка столбца
  seriesData.push({data: columnData, label: column.headerName, area: isField });
});

const additProps = ( isField ?
  {
    innerRadius: 40,
    paddingAngle: 10,
    cornerRadius: 5,
    startAngle: -90,
    endAngle: 180
  } : {});

const pieSeries = [
  {
      data: seriesData.map((item, index) => ({
          id: index,
          value: item.data.reduce((sum, num) => sum + num, 0),
          label: item.label
      })), ...additProps
  }
];

console.log(seriesData);
 // Создаем массив для оси X
 const xAxisData = rows.map((row, index) => `Data ${index + 1}`);

 const indexedXAxisData = xAxisData.map((item, index) => index);

 // Создаем массив для легенд
 const legends = columns.map(column => column.headerName);

  return (
    <Box sx={{height: 'calc(100% - 30px)', margin: '15px'}}>
      {asRender === 'Столбчатая диаграмма' && (
      <BarChart
        ref={forSaveImage1Ref}
        series={seriesData}
        xAxis={[{ data: xAxisData, scaleType: 'band' }]}
        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
        sx={{height: '100%'}}
      />
      )}

      {asRender === 'График' && (
      <LineChart
        ref={forSaveImage2Ref}
        xAxis={[{ data: indexedXAxisData }]}
        series={seriesData}
        sx={{height: '100%'}}
      />
      )}

      {asRender === 'Круговая диаграмма' && (
      <PieChart
      ref={forSaveImage3Ref}
        series={pieSeries}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'top', horizontal: 'right' },
            padding: 0,
          },
        }}
        sx={{width: '100%', textAlign: 'center'}}
      />
      )}
    </Box>
  );
}

export default ChartPanel