import { AppBar, Typography, Grid, Box, Paper, Container } from '@mui/material';
import React, {useState, useRef} from 'react';
import HeaderApp from './Components/HeaderApp';
import FooterApp from './Components/FooterApp';
import ToolPanel from './Components/ToolPanel';
import ChartPanel from './Components/ChartPanel';
import {toPng} from 'html-to-image';


const Workspace = () => {
  const [columns, setColumns] = useState([]); // Состояние строк
  const [rows, setRows] = useState([]); // Состояние столбцов
  const [asRenderData, setAsRenderData] = useState(""); // Состояние выбора данных
  const [isField, setIsField] = useState(false);

  const forSaveImage1Ref = useRef(null)
  const forSaveImage2Ref = useRef(null)
  const forSaveImage3Ref = useRef(null)

  const [renderVariant, setRenderDataVariant] = useState([]);

  const downloadChartAsPng = () => {
    if (asRenderData == 'Круговая диаграмма') {
      downloadPng(forSaveImage3Ref)
    } else if (asRenderData == 'График') {
      downloadPng(forSaveImage2Ref)
    } else {
      downloadPng(forSaveImage1Ref)
    }

    
};

const downloadPng = async (forSaveImageRef) => {
  if (forSaveImageRef.current) {
    try {
        const dataUrl = await toPng(forSaveImageRef.current);
        const link = document.createElement('a');
        link.download = 'chart.png';
        link.href = dataUrl;
        link.click();
    } catch (error) {
        console.error('Произошла ошибка при скачивании изображения:', error);
    }
}
}

  return (
    <Box sx={
      {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      } 
    }>
      <Box>
        <HeaderApp />
      </Box>

      <Box sx={
        {
          flexGrow: 1,
          marginTop: '70px'
        }
      }>
        <Grid container sx={{width: '100%', height: 'calc(100vh - 141px)', background: '#f1f1f1'}}>
          <Grid item md={12} lg={4} sx={{width: '100%', height: 'calc(100vh - 141px)', background: '#eee'}}>
            <ToolPanel columns={columns} setColumns={setColumns} rows={rows} setRows={setRows} asRenderData={asRenderData} setAsRenderData={setAsRenderData} renderDataVariant={renderVariant} setIsField={setIsField} saveImage={downloadChartAsPng}/>
          </Grid>
          <Grid item md={12} lg={8} sx={{width: '100%', height: 'calc(100vh - 141px)', background: '#fff'}}>
            <ChartPanel columns={columns} rows={rows} asRender={asRenderData} setRenderDataVariant={setRenderDataVariant} isField={isField} forSaveImage1Ref={forSaveImage1Ref} forSaveImage2Ref={forSaveImage2Ref} forSaveImage3Ref={forSaveImage3Ref} />
          </Grid>
          <Grid item md={12} lg={12} sx={{width: '100%'}}>
            <FooterApp />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Workspace;
